import React, { ChangeEvent, useState } from "react";
import { mincompany } from "../../apis/instance";
import { authorization } from "../../apis/authorization";
import Button from "../atoms/Button";
import { Transforms } from "slate";
import { useSlate } from "slate-react";
import { useSetAtom } from "jotai";
import { imageEmbedAtom } from "../../context";

const ImageEmbedModal = () => {
  const setMock = useSetAtom(imageEmbedAtom);

  const [url, setUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const editor = useSlate();
  const handleSend = () => {
    const newBlock = {
      type: "image",
      data: imageUrl,
      link: url,
      children: [{ text: "" }],
    };

    Transforms.insertNodes(editor, newBlock);

    const newBlock2 = {
      type: "paragraph",
      children: [{ text: " ", font: "15px" }],
    };

    Transforms.insertNodes(editor, newBlock2);
    setMock(false);
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);

      const { data } = await mincompany.post(
        "/files",
        formData,
        authorization()
      );
      setImageUrl(data);
    }
  };

  return (
    <>
      <div
        onClick={() => setMock(false)}
        className="w-screen h-screen fixed left-0 top-0 z-[10000] bg-black opacity-35"
      />
      <div className="z-[20000] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[60vw] h-[60vh] bg-white flex flex-col justify-center items-center px-20 gap-10">
        <div className="w-full h-12 flex flex-col justify-center gap-10 items-center">
          <input
            onChange={handleFileUpload}
            type="file"
            className="w-full h-full outline-none px-4 border-2 border-primary py-4"
            placeholder="사진을 업로드하세요"
          />
          <input
            onChange={({ target: { value } }) => setUrl(value)}
            value={url}
            className="w-full h-full outline-none px-4 border-2 border-primary py-4"
            placeholder="링크를 입력하세요"
          />
          {imageUrl && (
            <img src={imageUrl} className="w-[20vw] h-auto" alt="asd" />
          )}
          <Button onClick={handleSend}>확인</Button>
        </div>
      </div>
    </>
  );
};

export default ImageEmbedModal;
