import React, { ChangeEvent, useState } from "react";
import { mincompany } from "../../apis/instance";
import { authorization } from "../../apis/authorization";
import Button from "../atoms/Button";
import { Transforms } from "slate";
import { useSlate } from "slate-react";
import { useSetAtom } from "jotai";
import { mockAtom } from "../../context";
import classNames from "classnames";

const EmbedModal = () => {
  const setMock = useSetAtom(mockAtom);
  const [data, setData] = useState<any>();
  const [flag, setFlag] = useState(0);

  const [url, setUrl] = useState("");
  const getEmbed = async () => {
    if (!url) return;
    try {
      const { data } = await mincompany.get("/embeds", {
        params: { url },
        ...authorization(),
      });
      console.log(data);
      setData(data);
      setFlag(1);
    } catch {
      setFlag(-1);
    }
  };

  const editor = useSlate();
  const handleSend = () => {
    if (!url) return;
    const newBlock = {
      type: "link",
      children: [{ text: "", data }],
    };

    Transforms.insertNodes(editor, newBlock);

    const newBlock2 = {
      type: "paragraph",
      children: [{ text: " ", font: "15px" }],
    };

    Transforms.insertNodes(editor, newBlock2);
    setMock(false);
  };

  const [isSelfMode, setIsSelfMode] = useState(false);
  const [title, setTitle] = useState("");
  const [linkHref, setLinkHref] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");

  const self = () => {
    setIsSelfMode(true);
  };

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target && e.target.files) {
      console.log(e.target.files[0]);
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      const { data } = await mincompany.post(
        "/files",
        formData,
        authorization()
      );
      setImageUrl(data);
    }
  };

  const addSelf = () => {
    const newBlock = {
      type: "link",
      children: [
        {
          text: "",
          data: { title, description, image: imageUrl, url: linkHref },
        },
      ],
    };

    Transforms.insertNodes(editor, newBlock);

    const newBlock2 = {
      type: "paragraph",
      children: [{ text: " ", font: "15px" }],
    };

    Transforms.insertNodes(editor, newBlock2);
    setMock(false);
  };

  return (
    <>
      <div
        onClick={() => setMock(false)}
        className="w-screen h-screen fixed left-0 top-0 z-[10000] bg-black opacity-35"
      />
      <div
        className={classNames(
          "z-[20000] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[60vw] bg-white flex flex-col justify-center items-center px-20 gap-10",
          isSelfMode ? "h-[80vh]" : "h-[60vh]"
        )}
      >
        {!isSelfMode ? (
          <>
            <div className="w-full h-12 relative border-solid border-primary border-[1.5px] flex items-center">
              <input
                onChange={({ target: { value } }) => setUrl(value)}
                value={url}
                className="w-full h-full absolute outline-none px-4"
                placeholder="링크를 입력하세요"
              />
              <button
                onClick={getEmbed}
                className="absolute right-0 px-5 bg-primary h-full text-white font-semibold"
              >
                검색
              </button>
            </div>
            {flag === -1 ? (
              <span className="text-red-600">
                유효한 사이트 주소가 아닙니다.
              </span>
            ) : (
              flag === 1 && (
                <a
                  href={data.url}
                  className="w-full h-32 flex items-center border-solid border-[1px] border-[#ccc] cursor-pointer"
                >
                  <img src={data.image} alt="logo" className="h-full" />
                  <div className="flex flex-col p-6 h-full justify-between">
                    <h1 className=" font-semibold">{data.title}</h1>
                    <span className=" text-ellipsis overflow-hidden line-clamp-1">
                      {data.description}
                    </span>
                    <span>{data.url}</span>
                  </div>
                </a>
              )
            )}
          </>
        ) : (
          <div className="w-full gap-4 flex-col flex">
            <input
              onChange={({ target: { value } }) => setLinkHref(value)}
              value={linkHref}
              className="w-full h-12 outline-none px-4 border-2 border-solid border-primary"
              placeholder="링크를 입력하세요"
            />
            <input
              onChange={({ target: { value } }) => setTitle(value)}
              value={title}
              className="w-full h-12 outline-none px-4 border-2 border-solid border-primary"
              placeholder="표시할 타이틀을 입력하세요"
            />
            <input
              onChange={({ target: { value } }) => setDescription(value)}
              value={description}
              className="w-full h-12 outline-none px-4 border-2 border-solid border-primary"
              placeholder="표시할 설명을 입력하세요"
            />
            <input
              onChange={handleFileUpload}
              type="file"
              className="w-full h-12 outline-none px-4 border-2 border-solid border-primary"
              placeholder="사진을 업로드하세요"
            />
            <div className="border-2 border-solid border-black p-4 flex flex-col gap-4">
              <h1>미리보기</h1>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                href={linkHref}
                target="_blank"
                rel="noreferrer"
                className="w-1/2 h-32 flex items-center border-solid border-[1px] border-[#ccc] cursor-pointer"
              >
                {imageUrl && (
                  <img src={imageUrl} alt="logo" className="h-full max-w-32" />
                )}
                <div className="flex flex-col p-6 h-full justify-between">
                  <h1 className=" font-semibold">{title}</h1>
                  <span className=" text-ellipsis overflow-hidden line-clamp-1 text-[14px] text-[#aaa]">
                    {description}
                  </span>
                  <span className="text-[12px] text-primary">{linkHref}</span>
                </div>
              </a>
            </div>
            <button
              onClick={addSelf}
              className="w-fit px-5 bg-primary h-fit py-2 text-white font-semibold"
            >
              추가
            </button>
          </div>
        )}
        <div className="flex items-center gap-4">
          {!isSelfMode ? (
            <>
              <Button onClick={handleSend}>확인</Button>
              <Button
                className="bg-white border-2 border-primary !text-primary"
                onClick={self}
              >
                임베드 직접 추가
              </Button>
            </>
          ) : (
            <Button
              className="bg-white border-2 border-primary !text-primary"
              onClick={() => setIsSelfMode(false)}
            >
              직접 추가 취소
            </Button>
          )}{" "}
        </div>
      </div>
    </>
  );
};

export default EmbedModal;
