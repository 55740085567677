import React from "react";
import MenuIcon from "../assets/MenuIcon";
import SearchIcon from "../assets/SearchIcon";
import TabListIcon from "../assets/TabListIcon";
import classNames from "classnames";

interface PostHeaderProps {
  title: string;
  preview?: boolean;
}

const PostHeader = ({ title, preview }: PostHeaderProps) => {
  return (
    <header
      className={classNames(
        "w-full h-[55px] border-solid border-b-[1px] border-[#c4c4c4] flex items-center px-[12px] justify-between",
        !preview && "fixed"
      )}
    >
      <span className="font-bold text-[#02C75A] text-[20px]">blog</span>
      {/* <span className="logo w-[48px] h-[26px] z-10 pr-2" /> */}
      <nav className="text-[16px] font-extrabold flex gap-1 p-1">
        <TabListIcon />
        <span className="line-clamp-1">{title}</span>
      </nav>
      <ul className="flex gap-[8px]">
        <li>
          <SearchIcon />
        </li>
        <li>
          <MenuIcon />
        </li>
      </ul>
    </header>
  );
};

export default PostHeader;
