import React from "react";
import { useParams } from "react-router-dom";
import PostHeader from "../components/PostHeader";
import PostContent from "../components/PostContent";
import PostFooter from "../components/PostFooter";
import CommentList from "../components/comments/CommentList";
import { useQuery } from "@tanstack/react-query";
import { mincompany } from "../apis/instance";

const Post = () => {
  const { id } = useParams();
  const { isSuccess, data } = useQuery({
    queryKey: ["post", id],
    queryFn: async () => (await mincompany.get(`/posts/${id}`)).data,
  });

  return (
    <main className="w-full h-full">
      {isSuccess && (
        <>
          {/* <PostHeader title={data.title} preview /> */}
          <div className="w-full h-[66px] bg-[#FF8585] fixed top-0 flex items-center px-10 shadow-lg justify-between z-[1000]">
            <hgroup className="flex items-center gap-20">
              <div
                className="text-white font-bold font-['Pretendard'] text-[22px]"
              >
                luckylife BLOG
              </div>
            </hgroup>
          </div>
          <PostContent
            title={data.title}
            author={data.author}
            profileImageUrl={data.authorProfileImageUrl}
            createdAt={data.createdAt}
            content={data.content}
          />
          {/* <PostFooter tags={data.tags} likeCount={data.likeCount} /> */}
        </>
      )}
      {/* <div className="w-full flex justify-center">
        <CommentList user={data} readOnly postId={Number(id)} />
      </div> */}
    </main>
  );
};

export default Post;
