import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider as JotaiProvider } from "jotai";
import { ToastContainer } from "react-toastify";

import Post from "./pages/Post";
import Login from "./pages/Login";
import AdminHome from "./pages/(admin)/AdminHome";
import AdminPost from "./pages/(admin)/AdminPost";
import NotFound from "./pages/NotFound";
import AdminManageProfile from "./pages/(admin)/AdminManageProfile";
import GlobalModal from "./components/modals/GlobalModal";
import AdminEdit from "./pages/(admin)/AdminEdit";
import AdminWrite from "./pages/(admin)/AdminWrite";

import "react-toastify/dist/ReactToastify.css";

const GA_TRACKING_ID = "G-LVXRNMJLRE"; // GA4 측정 ID로 교체
const GTM_ID = "GTM-5MKST7N2"; // GTM 컨테이너 ID로 교체
const FB_PIXEL_ID = "420986867545524"; // Meta Pixel ID로 교체

ReactGA.initialize(GA_TRACKING_ID);

const tagManagerArgs = {
  gtmId: GTM_ID,
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    ReactPixel.pageView();
  }, [location]);

  return (
    <>
      <GlobalModal />
      <ToastContainer autoClose={2000} hideProgressBar />
      <Routes>
        <Route path="/post/:id" element={<Post />} />
        <Route path="/login" element={<Login />} />
        {/* -- admin -- */}
        <Route path="/admin/home" element={<AdminHome />} />
        <Route path="/admin/:id" element={<AdminPost />} />
        <Route path="/admin/:id/edit" element={<AdminEdit />} />
        <Route path="/admin/write" element={<AdminWrite />} />
        <Route path="/admin/manage-profile" element={<AdminManageProfile />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

const App = () => {
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    ReactPixel.init(FB_PIXEL_ID);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <JotaiProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </JotaiProvider>
    </QueryClientProvider>
  );
};

export default App;
