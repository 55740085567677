import React, { ChangeEvent, useState } from "react";
import CommentItem from "./CommentItem";
import SelectProfile from "../atoms/SelectProfile";
import Button from "../atoms/Button";
import { toast } from "react-toastify";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { mincompany } from "../../apis/instance";
import { authorization } from "../../apis/authorization";
import Check from "../atoms/Check";

interface CommentListProps {
  readOnly?: boolean;
  postId: number;
  user: any;
}

const CommentList = ({ readOnly, postId, user }: CommentListProps) => {
  const [contents, setContents] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [like, setLike] = useState<number>();
  const [isPrivate, setIsPrivate] = useState(false);
  const [userId, setUserId] = useState(0);

  const { isSuccess, data } = useQuery({
    queryKey: ["comment", postId],
    queryFn: async () =>
      (
        await mincompany.get("/comments", {
          params: {
            postId,
          },
        })
      ).data,
  });

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  };

  const handleTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  };

  const handleLikeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (!isNaN(Number(value))) {
      setLike(Number(value));
    }
  };

  const { mutateAsync: commentRegist } = useMutation({
    mutationFn: (res: any) =>
      mincompany.post("/comments", res, authorization()),
  });

  const queryClient = useQueryClient();
  const handleRegister = async () => {
    const utcDate = new Date(`${date}T${time}Z`);
    console.log(utcDate);

    await commentRegist({
      userId,
      postId,
      parentCommentId: null,
      content: contents,
      isPrivate,
      likeCount: like,
      createdAt: new Date(`${date}T${time}Z`),
    });
    toast.success("댓글을 생성했습니다!");
    setContents("");
    setDate("");
    setTime("");
    setLike(0);
    setUserId(0);
    queryClient.invalidateQueries({ queryKey: ["comment", postId] });
  };

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full bg-[#F8F8F8] my-10 p-1 flex items-center justify-center px-[4vw]">
        {user && (
          <div className="p-4 rounded-lg w-full flex flex-col border-solid border-[#E6E6E6] border-[1px] shadow-sm bg-white gap-3">
            <hgroup className="w-full items-center flex justify-between">
              <img
                src={user.authorProfileImageUrl}
                className="w-[60px] h-[60px] rounded-full"
                alt={user.author}
              />
              <Button className="!text-[14px]">이웃 추가</Button>
            </hgroup>
            <div className="flex flex-col mx-2">
              <div className="flex items-center gap-2">
                <span className="text-[20px] font-semibold">{user.author}</span>
                <span className="text-[14px] font-medium">
                  이웃 {user.neighborCount}
                </span>
              </div>
              <span className="line-clamp-2 text-[14px] text-ellipsis font-medium text-[#666]">
                {user.authorBio}
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="w-full flex flex-col">
        {!readOnly && (
          <section className="w-full flex px-6 bg-[#f8f8f8] p-[16px] border-solid border-[2px] rounded-md gap-4">
            <section className="w-full flex flex-col gap-2">
              <header className="flex items-center justify-between">
                <SelectProfile setUserId={setUserId} />
                <figure className="flex items-center gap-1">
                  <Button
                    onClick={handleRegister}
                    className="!text-[10px] !px-3 !py-[0]"
                  >
                    등록
                  </Button>
                </figure>
              </header>
              <article className="flex flex-col">
                <textarea
                  className="text-[13px] font-['helvetica'] p-4 border-solid border-[1.5px] border-[#ccc] outline-none"
                  onChange={({ target: { value } }) => setContents(value)}
                  value={contents}
                />
                <div className="flex items-center py-2">
                  <input
                    onChange={handleDateChange}
                    value={date}
                    type="date"
                    className="bg-transparent text-sm"
                  />
                  <input
                    onChange={handleTimeChange}
                    value={time}
                    type="time"
                    className="bg-transparent text-sm"
                  />
                </div>
                <div className="flex items-center gap-1">
                  <span className="text-sm font-semibold">좋아요 수</span>
                  <input
                    placeholder="0"
                    onChange={handleLikeChange}
                    value={like}
                    className="bg-transparent rounded-md w-32 text-sm border-solid border-2 border-[#ccc] outline-none focus:border-primary focus:duration-300 transition-colors px-2"
                  />
                  <div className="ml-6 flex gap-2 items-center">
                    <span className="text-sm font-semibold">비공개 여부</span>
                    <Check
                      val={isPrivate}
                      set={() => setIsPrivate((pr) => !pr)}
                    />
                  </div>
                </div>
              </article>
            </section>
          </section>
        )}
      </div>
      <div className="w-full flex flex-col mb-10">
        {isSuccess &&
          data.map((comment: any) => (
            <CommentItem
              key={comment.id}
              readOnly={readOnly}
              postId={postId}
              comment={comment}
            />
          ))}
      </div>
    </div>
  );
};

export default CommentList;
